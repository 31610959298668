import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const StudentFeesStructureInstallmentByDueDate = React.lazy(() => import("modules/account/studentFeesStructureInstallmentByDueDate"));

const routes = {
    element: <StudentFeesStructureInstallmentByDueDate />,
    title: "Overdue Fees Report",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    type: "item",
    path: ["/account/studentfeesstructureinstallmentbyduedates"],
};

export default routes;
