import { USER_ROLE } from "constants/AppConstant";
import { IoLogIn } from "react-icons/io5";
import studentLoginRoutes from '../../student/loginRoutes';
import adminLoginRoutes from './adminLoginRoutes';
import proceedToLoginRoutes from './proceedToLoginRoutes';


const routes = {
    title: "LOGIN ",
    permittedRole: [USER_ROLE.PUBLIC],
    type: "group",
    icon: <IoLogIn />,
    path: ["/register"],
    children: [
        proceedToLoginRoutes,
        studentLoginRoutes,
        adminLoginRoutes,
    ],
};

export default routes;
