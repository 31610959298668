import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const ExamStudent = React.lazy(() =>
    import("modules/exam/subjectWiseExamStudentDetails")
);

const routes = {
    element: <ExamStudent />,
    title: "Exam Result",
    type: "item",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: [
        "/examstudents"
    ],
};

export default routes;
