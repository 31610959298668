import React from "react";

const Register = React.lazy(() => import("modules/auth/Register"));
const ForgotPassword = React.lazy(() => import("modules/auth/ForgetPassword"));

export const authRouteConfig = [

  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />,
  },
];

