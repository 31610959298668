import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const EntranceRegister = React.lazy(
  () => import("modules/student/entrance/entranceStudentRegistration")
);

const routes = {
  title: "NSAT Registration",
  permittedRole: [USER_ROLE.PUBLIC],
  path: ["entrance/register"],
  element: <EntranceRegister />,
  type: "item",
};

export default routes;
