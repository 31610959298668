import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const TestSeriesRegister = React.lazy(
    () => import("modules/student/register/testSeriesRegistration")
);

const routes = {
    title: "Test Series Registration",
    permittedRole: [USER_ROLE.PUBLIC],
    path: ["testseries/register"],
    element: <TestSeriesRegister />,
    type: "item",
};

export default routes;
