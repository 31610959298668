import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const Assignment = React.lazy(() =>
  import("modules/assignment/assignmentSetup/assignment/Form/index")
);

const routes = {
  element: <Assignment />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/assignment/create", "/assignment/edit"],
};

export default routes;
