import { USER_ROLE } from "constants/AppConstant";
import { MdAssignment } from "react-icons/md";
import assignmentDataUploadRoutes from './assignmentDataUploadRoutes';
import assignmentFormRoutes from './assignmentFormRoutes';
import assignmentSetupRoutes from "./assignmentSetupRoutes";
import assignmentSummaryRoutes from "./assignmentSummaryRoutes";
import studentAssignmentRoutes from "./studentAssignmentRoutes";

const menu = {
    id: "Assignment",
    title: "Assignment",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    type: "collapse",
    icon: <MdAssignment />,
    path: ["/assignmentMenu"],
    children: [assignmentSetupRoutes, assignmentFormRoutes, studentAssignmentRoutes, assignmentDataUploadRoutes, assignmentSummaryRoutes],
};

export default menu;
