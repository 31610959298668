import { USER_ROLE } from "constants/AppConstant";
import React from "react";
const StudentFees = React.lazy(() =>
  import("modules/account/studentFeesReport")
);

const routes = {
  title: "Fees Installment Report",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/account/feesreports"],
  element: <StudentFees />,
  type: "item",
};

export default routes;
