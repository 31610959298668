import { SYSTEM_CONSTANTS, USER_ROLE } from "constants/AppConstant";
import { useNotificationActionsContext } from "contexts/common/notificationContext";
import {
  getLoginRouteFromRoleName,
  getRoleFromLocalStorage
} from "helpers/GetRole";
import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useState } from "react";
import jwtAxios, { setAuthToken } from "./index";

import AppSettings from "constants/AppEnum";
import { useNavigate } from "react-router-dom";

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }) => {
  const { fetchStart, fetchSuccess, fetchError } =
    useNotificationActionsContext();
  const navigator = useNavigate();
  const [JWTAuthData, setJWTAuthData] = useState({
    user: {
      role_name: getRoleFromLocalStorage(),
    },
    isAuthenticated: false,
    isLoading: true,
    permissions: AppSettings?.PUBLIC?.permissions || {},
  });

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      setAuthToken(authToken);
      loginUser(getLoginRouteFromRoleName(JWTAuthData?.user?.role_name), null);
    } else {
      fetchSuccess();
      setJWTAuthData({
        ...JWTAuthData,
        isLoading: false,
      });
    }
  }, []);


  useEffect(() => {
    const interceptor = jwtAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {

        const previousRequest = error?.config;

        if (error?.response?.status === 401) {
          logout();
        }
        if (
          error?.response?.data?.isTokenExpired &&
          localStorage?.getItem("refreshToken")
        ) {
          jwtAxios
            .post("/refresh-token", {
              refreshToken: localStorage?.getItem("refreshToken"),
            })
            .then((data) => {
              setAuthToken(data.data?.authToken, data.data?.refreshToken);

              previousRequest.headers.Authorization =
                "Bearer " + data.data?.authToken;


              return Promise.resolve(jwtAxios(previousRequest));
            })
            .catch((error) => { });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      jwtAxios.interceptors.request.eject(interceptor);
    }
  }, [])




  const loginUser = async (route, formData, setSubmitting = () => { }) => {
    fetchStart();
    setSubmitting(true);
    try {
      const { data } = await jwtAxios.post(route, { formData });
      setAuthToken(data?.authToken, data?.refreshToken);

      setJWTAuthData({
        user: data.user,
        permissions: data?.permissions || {},
        isAuthenticated: true,
        isLoading: false,
      });
      // localStorage.setItem("userRole", data?.user?.role_name);
      fetchSuccess();
    } catch (error) {
      localStorage.setItem("userRole", USER_ROLE.PUBLIC);
      setJWTAuthData({
        ...JWTAuthData,
        user: {
          ...JWTAuthData?.user,
          role_name: USER_ROLE.PUBLIC,
        },
        isLoading: false,
      });
      fetchError(error?.response?.data?.message || "Something went wrong");
    } finally {
      setSubmitting(false);
    }
  };

  const registerUser = async ({ name, email, password }) => {
    fetchStart();
    try {
      const { data } = await jwtAxios.post("users", { name, email, password });
      localStorage.setItem("token", data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get("/auth");

      setJWTAuthData({
        user: { ...res.data },
        isAuthenticated: true,
        isLoading: false,
      });
      fetchSuccess();
    } catch (error) {
      setJWTAuthData({
        ...JWTAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      fetchError(error?.response?.data?.message || "Something went wrong");
    }
  };

  const logout = (event, redirectPath) => {
    if (redirectPath) {
      navigator(redirectPath)
    }
    localStorage.removeItem("token");
    setAuthToken();
    setJWTAuthData({
      user: {
        role_name: USER_ROLE.PUBLIC,
      },
      isLoading: false,
      isAuthenticated: false,
      permissions: AppSettings?.PUBLIC?.permissions || {},
    });
  };

  const isSuperAdmin = () => JWTAuthData?.user?.role_name === USER_ROLE.SUPER_ADMIN;

  const hasPermission = (route, permission) =>
    JWTAuthData?.user?.role_name === USER_ROLE.SUPER_ADMIN
      ? true
      : JWTAuthData?.permissions?.[route] &&
      JWTAuthData?.permissions?.[route]?.[permission];

  const hasAllPermissions = (route) => {
    if (isSuperAdmin()) return true;
    const permissions = Object.keys(SYSTEM_CONSTANTS.PERMISSIONS_OBJ);
    for (const permission of permissions) {
      if (!JWTAuthData?.permissions?.[route]?.[permission]) return false;
    }
    return true;
  };

  const hasAnyPermissionToRoutes = (routes = []) => {
    return routes.filter(route => hasAnyOneActionPermission(route.path))
  }

  const hasAllActionPermissions = (route, permissions = []) => {
    if (isSuperAdmin()) return true;
    for (const permission of permissions) {
      if (!JWTAuthData?.permissions?.[route]?.[permission]) return false;
    }
    return true;
  };


  const hasAnyOneActionPermission = (route, permissions = Object.values(SYSTEM_CONSTANTS.PERMISSIONS_OBJ)) => {
    if (isSuperAdmin()) return true;
    for (const permission of permissions) {
      if (JWTAuthData?.permissions?.[route]?.[permission]) return true;
    }
    return false;
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...JWTAuthData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          hasAnyPermissionToRoutes,
          registerUser,
          loginUser,
          logout,
          hasPermission,
          hasAllPermissions,
          hasAnyOneActionPermission,
          hasAllActionPermissions,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};

export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fetchStart: PropTypes.func,
  fetchSuccess: PropTypes.func,
  fetchError: PropTypes.func,
};
