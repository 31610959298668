import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const ExamDataUpload = React.lazy(() =>
    import("modules/exam/examDataUpload")
);

const routes = {
    element: <ExamDataUpload />,
    title: "Update Exam Result",
    type: "item",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: [
        "/examresults"
    ],
};

export default routes;
