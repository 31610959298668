import { USER_ROLE } from "constants/AppConstant";
import { PiRankingFill } from "react-icons/pi";
import examDataUpdateRoutes from "./examDataUpdateRoutes";
import examFormRoutes from "./examFormRoutes";
import examScheduleSummaryRoutes from './examScheduleSummaryRoutes';
import examSetupRoutes from "./examSetupRoutes";
import examStudentRoutes from './examStudentRoutes';
import generateExamReportCardRoutes from './generateExamReportCardRoutes';

const menu = {
  id: "Exam",
  title: "Exam",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: "collapse",
  icon: <PiRankingFill />,
  path: ["/examMenu"],
  children: [examSetupRoutes, examFormRoutes, examStudentRoutes, examDataUpdateRoutes, generateExamReportCardRoutes, examScheduleSummaryRoutes],
};

export default menu;
