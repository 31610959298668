import { USER_ROLE } from "constants/AppConstant";
import React from "react";
const Login = React.lazy(() => import("modules/auth/Login"));

const routes = {
    element: <Login />,
    title: "Office Login",
    type: "item",
    permittedRole: [USER_ROLE.PUBLIC],
    path: ["/login"],
};

export default routes;
